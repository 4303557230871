import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const technicalKeys = [
  { displayName: "ERP (KWh/day)", dataKey: "erpCalculationValue" },
  { displayName: "NRP (kg/day)", dataKey: "nrpCalculationValue" },
  { displayName: "Part Availability", dataKey: "partAvailability" },
  {
    displayName: "Skilled/Unskilled Labour Ratio",
    dataKey: "skilledUnskilledRatio",
  },
  { displayName: "Land Use(ha)", dataKey: "landUse" },
  { displayName: "Water Use(KLD)", dataKey: "waterUse" },
  {
    displayName: "Energy Use(KW / day)",
    dataKey: "energyUse",
  },
  // {
  //   displayName: "Transport Fuel Energy Use (MJ/day)",
  //   dataKey: "fuelEnergyUsage",
  // },
];

const environmentalKeys = [
  { displayName: "GHG Emission (Kg CO2 equiv/day)", dataKey: "ghgEmissions" },
  { displayName: "Noise Emission", dataKey: "noise" },
  { displayName: "Odor Emission", dataKey: "odour" },
];

const socialKeys = [
  { displayName: "Job Creation (people)", dataKey: "jobsCreated" },
  { displayName: "Occupational H&S", dataKey: "healthSafetyRiskFactor" },
  { displayName: "Social Acceptance", dataKey: "societalAcceptanceFactor" },
  { displayName: "Women & Youth Inclusion", dataKey: "womenYouthInclusion" },
];
const economicKeys = [
  // { displayName: "NPV", dataKey: "NPV" },
  { displayName: "ROI (%)", dataKey: "ROI" },
  { displayName: "BCR Ratio", dataKey: "BCR" },
];

const resultView = [
  // { displayName: "NPV", dataKey: "NPV" },
  { displayName: "Feasibility Index", dataKey: "fInd" },
  { displayName: "Rank", dataKey: "rank" },
];
const ResultTable = ({
  erpValue,
  calculationDetails,
  businessModelList,
  callFrom,
}) => {
  //console.log(businessModelList,erpValue)
  const [columnNames, setColumnNames] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let selectedBusinessModels = erpValue?.map(
      (ele) => ele.businessModelIds[0]
    );
    let businessModelName = getTypesByIds(selectedBusinessModels);
    let newErpList = erpValue?.map((ele, ind) => {
      let obj = {
        erpCalculationValue: ele?.erpValue,
        businessModel: businessModelName[ind],
      };
      return obj;
    });
    let dataForTable = createTableStructureData(newErpList, businessModelName);
    setTableData(dataForTable);
  }, [erpValue, calculationDetails]);

  function getTypesByIds(ids) {
    return ids?.map((id) => businessModelList.find((x) => x.id == id)?.type);
  }

  const createTableStructureData = (newErpList, businessModelName) => {
    console.log(calculationDetails.roi);
    const calculationResultByBusinessModel =
      calculationDetails?.technologyDataDtos
        ?.filter((item) =>
          businessModelName.some(
            (businessModelSle) =>
              businessModelSle.toLowerCase() ===
              item.businessModel.toLowerCase()
          )
        )
        .map((ele, index) => {
          const erp = newErpList.find(
            (e) =>
              e.businessModel.toLowerCase() === ele.businessModel.toLowerCase()
          );
          return {
            ...ele,
            erpCalculationValue: erp?.erpCalculationValue || null,
            ghgEmissions:
              calculationDetails?.ghgEmissions[
                index == 0 ? 1 : index == 1 ? 0 : index
              ],
            landUse: calculationDetails?.businessModelTechicalGroup.filter(
              (lu) =>
                lu.businessModel.toLowerCase() ==
                ele.businessModel.toLowerCase()
            )[0]?.landUse,
            waterUse: calculationDetails?.businessModelTechicalGroup.filter(
              (wu) =>
                wu.businessModel.toLowerCase() ==
                ele.businessModel.toLowerCase()
            )[0]?.waterUse,
            energyUse: calculationDetails?.businessModelTechicalGroup.filter(
              (eu) =>
                eu.businessModel.toLowerCase() ==
                ele.businessModel.toLowerCase()
            )[0]?.energyUse,
            // NPV: calculationDetails?.npvGroupWithBusinessModels?.filter(
            //   (np) =>
            //     np.businessModel.toLowerCase() ==
            //     ele.businessModel.toLowerCase()
            // )[0]?.npv[0],
            ROI: calculationDetails?.roi[
              index == 0 ? 1 : index == 1 ? 0 : index
            ],
            BCR: calculationDetails?.bcr[
              index == 0 ? 1 : index == 1 ? 0 : index
            ],
          };
        });

    const keysToInclude =
      callFrom === "MCDA"
        ? [
            "erpCalculationValue",
            "partAvailability",
            "skilledUnskilledRatio",
            "landUse",
            "waterUse",
            "energyUse",
            "ghgEmissions",
            "noise",
            "odour",
            "jobsCreated",
            "healthSafetyRiskFactor",
            "societalAcceptanceFactor",
            "womenYouthInclusion",
            // "NPV",
            "ROI",
            "BCR",
            "fInd",
            "rank",
          ]
        : [
            "erpCalculationValue",
            "partAvailability",
            "skilledUnskilledRatio",
            "landUse",
            "waterUse",
            "energyUse",
            "ghgEmissions",
            "noise",
            "odour",
            "jobsCreated",
            "healthSafetyRiskFactor",
            "societalAcceptanceFactor",
            "womenYouthInclusion",
            // "NPV",
            "ROI",
            "BCR",
          ];
    const transformedData = keysToInclude.map((key) => {
      const entry = { key };
      calculationResultByBusinessModel?.forEach((item) => {
        entry[item.businessModel] = item[key];
      });
      return entry;
    });
    setColumnNames(
      Object.keys(transformedData[0]).filter((col) => col !== "key")
    );
    console.log(transformedData);
    return transformedData;
  };

  const renderBcrValue = (dataKey, value) => {
    return dataKey == "BCR"
      ? Number(value).toFixed(6)
      : Number(value).toFixed(4);
  };
  const renderSection = (title, keys) => {
    return (
      <>
        <TableRow>
          <TableCell
            colSpan={columnNames.length + 1}
            align="left"
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              backgroundColor: "#d1dee2",
              color: "#003031",
              border: "2px solid #070750",
            }}
          >
            {title}
          </TableCell>
        </TableRow>
        {keys.map(({ displayName, dataKey }, index) => (
          <TableRow key={index}>
            <TableCell
              style={{
                fontSize: "0.7rem",
                fontWeight: "bold",
              }}
            >
              {displayName}
            </TableCell>
            {columnNames.map((col, colIndex) => (
              <TableCell
                key={colIndex}
                align="center"
                style={{
                  fontSize: "0.7rem",
                  fontWeight: 500,
                }}
              >
                {tableData.find((row) => row.key === dataKey)?.[col] !==
                undefined
                  ? renderBcrValue(
                      dataKey,
                      tableData.find((row) => row.key === dataKey)?.[col]
                    )
                  : "--"}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow
            className="bg-primary"
            style={{ border: "2px solid #1F3F49" }}
          >
            <TableCell
              style={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                backgroundColor: "#1F3F49",
              }}
              align="left"
              className="text-light"
            >
              Key
            </TableCell>
            {columnNames.map((col, index) => (
              <TableCell
                key={index}
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  backgroundColor: "#1F3F49",
                }}
                align="center"
                className="text-light"
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderSection("Technical", technicalKeys)}
          {renderSection("Environmental", environmentalKeys)}
          {renderSection("Social", socialKeys)}
          {renderSection("Economic", economicKeys)}
          {callFrom === "MCDA" ? renderSection("Result View", resultView) : ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultTable;
