// src/axiosConfig.js

import axios from "axios";
import { store } from "../../store/store";
import { logoutSuccess } from "../../authentication/AuthActions";

// Create an Axios instance
const axiosInstance = axios.create({
  // baseURL: 'https://api.example.com', // Uncomment and set your API base URL if needed
  // timeout: 10000, // Set a timeout if desired
  // headers: { 'Content-Type': 'application/json' }, // Set default headers if needed
});

// axiosInstance.interceptors.request.use(config=>{
//   const token = store.getState()
//   console.log(token)
//     if(config.url.startsWith(process.env.REACT_APP_GEOSERVER_URL)){
//         delete config.headers.Authorization;
//     }
//     console.log(config)
//     return config
// })

// Request Interceptor for Token Handling
axiosInstance.interceptors.request.use((config) => {
  const state = store.getState(); // Access entire Redux state
  const token = state.auth?.user?.token; // Access token from auth state

  if (token && !config.url.startsWith(process.env.REACT_APP_GEOSERVER_URL)) {
    config.headers.Authorization = `Bearer ${token}`;
  } else if (config.url.startsWith(process.env.REACT_APP_GEOSERVER_URL)) {
    delete config.headers.Authorization;
  }
  return config;
});

// Response Interceptor for Error Handling
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Dispatch logout and clear auth state
        store.dispatch(logoutSuccess());
      }
      // Handle other status codes (403, 500, etc.)
      // if (error.response.status === 403) {
      //     console.error('Access Denied: You do not have permission.');
      // }
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
