import { homeServerUrl } from "../environment/Environment";
import axiosInstance from "../external-library/axios-interceptor/Interceptor";

const addMapPoint = async (pointDetails) => {
  return await axiosInstance.post(`${homeServerUrl}/MapPoint`, pointDetails);
};

const getAllAddedPoint = async () => {
  return await axiosInstance.get(`${homeServerUrl}/MapPoint`);
};

const getPointById = async (pintId) => {
  return await axiosInstance.get(`${homeServerUrl}/MapPoint/${pintId}`);
};

const pointEditById = async (pintId, bodyData) => {
  return await axiosInstance.put(
    `${homeServerUrl}/MapPoint/${pintId}`,
    bodyData
  );
};

const deletePointById = async (pintId) => {
  return await axiosInstance.delete(`${homeServerUrl}/MapPoint/${pintId}`);
};

export {
  addMapPoint,
  getAllAddedPoint,
  getPointById,
  deletePointById,
  pointEditById,
};
