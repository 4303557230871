import { homeServerUrl } from "../environment/Environment";
import axiosInstance from "../external-library/axios-interceptor/Interceptor";
import qs from "qs";

const getAllCtegory = async () => {
  return await axiosInstance.get(`${homeServerUrl}/WasteCategory`);
};

const getAllBusinessModel = async () => {
  return await axiosInstance.get(`${homeServerUrl}/BusinessModel`);
};

const getAllValueAddedProducts = async () => {
  return await axiosInstance.get(`${homeServerUrl}/ValueAddedProduct/all`);
};

const getBusinessModelByValueAddedProducts = async (
  selectedWasteCategory,
  selectedValueAddedProduct,
  businessModelList
) => {
  console.log(businessModelList);
  return await axiosInstance.get(`${homeServerUrl}/ValueAddedProduct`, {
    params: {
      valueAddedProduct: selectedValueAddedProduct,
      wasteCategory: selectedWasteCategory,
      wasteCategoryBusinessModels: businessModelList,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

const addWasteQualification = async (wasteQualificationDetails) => {
  return await axiosInstance.post(
    `${homeServerUrl}/ERP`,
    wasteQualificationDetails
  );
};

const getTruckCapacity = async () => {
  return await axiosInstance.get(`${homeServerUrl}/Transpotation`);
};

const addBusinessModelwithWasteQualification = async (id, businessModelId) => {
  return await axiosInstance.put(`${homeServerUrl}/ERP/${id}`, {
    businessModelId,
  });
};

const getSenarioDetails = async (detailsOfErp) => {
  return await axiosInstance.get(`${homeServerUrl}/ERP/GetAll`, {
    params: {
      id: detailsOfErp.mapPointId,
      businessScenarioId: detailsOfErp.businessScenarioId,
    },
  });
};

const calculateNpv = async (detailsOfErp, id) => {
  return await axiosInstance.put(
    `${homeServerUrl}/MapPoint/npv`,
    detailsOfErp,
    {
      params: {
        location: id,
      },
    }
  );
};

const allCalculation = async (payLoad) => {
  return await axiosInstance.post(`${homeServerUrl}/Calculation`, payLoad, {
    params: {
      businessScenarioId: payLoad?.businessScenarioId,
    },
  });
};

const getExpectedLifeOfPlants = async () => {
  return await axiosInstance.get(`${homeServerUrl}/ExpectedLifeOfPlants`);
};

const getRRRObjective = async () => {
  return await axiosInstance.get(`${homeServerUrl}/RRRObjective`);
};

const downloadReportData = async (detailsForReport) => {
  return await axiosInstance.get(`${homeServerUrl}/reports/DownloadReport`, {
    params: {
      mapPointId: detailsForReport.mapPointId,
      businessScenarioId: detailsForReport.businessScenarioId
      // calculationTableId: detailsForReport.calculationTableId,
    },
    responseType: "blob",
  });
};

const mcdaCalculation = (details) =>
  axiosInstance.post(`${homeServerUrl}/MCDA/save`, null, {
    params: {
      businessScenarioId: details.businessScenarioId,
      rrrObjectiveId: details.rrrObjectiveId,
      calculationId: details.calculationId,
    },
  });

export {
  getAllCtegory,
  getAllBusinessModel,
  getAllValueAddedProducts,
  addWasteQualification,
  getSenarioDetails,
  addBusinessModelwithWasteQualification,
  calculateNpv,
  getTruckCapacity,
  allCalculation,
  getBusinessModelByValueAddedProducts,
  getExpectedLifeOfPlants,
  getRRRObjective,
  downloadReportData,
  mcdaCalculation,
};
