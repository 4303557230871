import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import "./SuitabalityCalculation.css";
import {
  TextField,
  Button,
  MenuItem,
  Slider,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  Typography,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  FormHelperText,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getPointById, pointEditById } from "../../services/addPointService";
import { getAllFeatureOfLayerByLayerName } from "../../services/FeatureInfoService";
import GeoJSON from "ol/format/GeoJSON";
import {
  addBusinessModelwithWasteQualification,
  addWasteQualification,
  allCalculation,
  calculateNpv,
  downloadReportData,
  getAllBusinessModel,
  getAllCtegory,
  getAllValueAddedProducts,
  getBusinessModelByValueAddedProducts,
  getExpectedLifeOfPlants,
  getRRRObjective,
  getSenarioDetails,
  getTruckCapacity,
} from "../../services/analysisService";
import Loader from "../../external-library/loader/Loader";
import WeightAnalysis from "../weight-analisis/WeightAnalysis";
// import { rrrObjectiveList } from "../../utilities/wasteCategorylist";
import { Material } from "../../external-library/material/Material";
import AlertSnakBar from "../dialogs/alert-message/AlertSnakBar";
import ResultTable from "../tables/suitablity-result-table/ResultTable";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#e9b007",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
// Custom Icon Components
const DumpsterFireIcon = ({ active, completed }) => (
  <i
    className="fa-solid fa-dumpster-fire"
    style={{
      fontSize: "1.2rem",
      color: active ? "orange" : completed ? "green" : "#003031",
    }}
  ></i>
);

const TruckDetail = ({ active, completed }) => (
  <i
    className="fa-solid fa-truck"
    style={{
      fontSize: "1.2rem",
      color: active ? "orange" : completed ? "green" : "#003031",
    }}
  ></i>
);

const ResultView = ({ active, completed }) => (
  <i
    className="fa-solid fa-table"
    style={{
      fontSize: "1.2rem",
      color: active ? "orange" : completed ? "green" : "#003031",
    }}
  ></i>
);

const Mcda = ({ active, completed }) => (
  <i
    className="fa-solid fa-calculator"
    style={{
      fontSize: "1.2rem",
      color: active ? "orange" : completed ? "green" : "#003031",
    }}
  ></i>
);
const SuitabilityCalculation = ({ id, onCancel }) => {
  //const { id } = useParams();
  const [aleartOpen, setAlertOpen] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [pointDetails, setPointDetails] = useState(null);
  const [districtDetails, setDistrictDetails] = useState({});
  const [category, setCategory] = useState([]);
  const [allWasteTypeList, setAllWasteTypeList] = useState([]);
  const [businessModelList, setbusinessModelList] = useState([]);
  const [wasteTypeList, setWasteTypeList] = useState([]);
  const [wasteQuantity, setWasteQuantity] = useState(null);
  const [businessModel, setBusinessModel] = useState([]);
  const [truckCapacity, setTruckCapacity] = useState([]);
  const [businessModelByWasteType, setBusinessModelByWasteType] = useState([]);
  const [handleDisableButtonForFirstStep, setHandleDisableButtonForFirstStep] =
    useState(true);
  const [
    handleDisableButtonForSecondStep,
    setHandleDisableButtonForSecondStep,
  ] = useState(true);
  const [businessSenarioDetails, setBusinessSenarioDetails] = useState({});
  const [erpValue, setErpValue] = useState(null);
  const [calculationDetails, setCalculationDetails] = useState([]);
  const [mcdaCalculationDetails, setMcdaCalculationDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [valuAddedProduct, setValueAddedProduct] = useState([]);
  const [selectedWasteCategory, setSelectedWasteCategory] = useState(null);
  const [selectedValueAddedProduct, setSelectedValueAddedProduct] =
    useState(null);
  const [expectedLifeOfPlants, setExpectedLifeOfPlants] = useState([]);
  const [rrrObjectiveList, setRrrObjectiveList] = useState([]);
  const [selectedRrrObjectiveList, setSelectedRrrObjectiveList] = useState({});
  const [detailsForMCDAApiCall, setDetailsForMCDAApiCall] = useState({});
  const [mcdaId, setMcdaId] = useState(null);
  const formStep1 = useForm({
    defaultValues: {
      wtfName: "",
      district: "",
      businessScenarioName: "",
      wasteCategory: "",
      wasteType: "",
      wasteQuantity: "",
      wasteCollectionEfficiency: 50,
      valueAddedProduct: "",
      businessModel: null,
      rrrObjective: null,
      // moisture: "",
      // oil: "",
    },
  });

  const formStep2 = useForm({
    // defaultValues: {
    //   distance: "",
    //   fuelEfficiency: "",
    //   truckCapacity: "",
    //   fuelEnergyUsed: "",
    //   ghgEmissions: "",
    //   // plantScale: "",
    //   // plantCapacityFactor: 50,
    //   // npv: "",
    // },
  });

  const formStep3 = useForm({
    defaultValues: {
      mvp: [],
    },
  });

  const {
    handleSubmit: handleSubmitStep1,
    control: controlStep1,
    register: registerStep1,
    reset: resetStep1,
    setValue: setValue1,
    formState: {
      errors: errorsStep1,
      isDirty: isDirtyStep1,
      isSubmitting: isSubmittingStep1,
    },
    getValues: getValuesStep1,
  } = formStep1;
  const {
    handleSubmit: handleSubmitStep2,
    control: controlStep2,
    register: registerStep2,
    reset: resetStep2,
    formState: {
      errors: errorsStep2,
      isDirty: isDirtyStep2,
      isSubmitting: isSubmittingStep2,
    },
    setValue: setValue2,
  } = formStep2;

  const {
    handleSubmit: handleSubmitStep3,
    control: controlStep3,
    register: registerStep3,
    reset: resetStep3,
    setValue: setValue3,
    watch: watchStep3,
  } = formStep3;

  const { fields } = useFieldArray({
    control: controlStep3,
    name: "mvp",
  });

  useEffect(() => {
    getAllValuAddedProductList();
    getTruckCapacity().then((res) => {
      setTruckCapacity(res.data);
    });
    getAllBusinessModelList();
    getExpectedLifeOfPlants()
      .then((res) => {
        setExpectedLifeOfPlants(res.data);
      })
      .catch((error) => {
        setExpectedLifeOfPlants([]);
      });
    getRRRObjective()
      .then((res) => {
        setRrrObjectiveList(res.data);
      })
      .catch((error) => {
        setRrrObjectiveList([]);
      });
  }, []);

  useEffect(() => {
    if (id == null) {
      resetStep1({
        wtfName: "",
        district: "",
        businessScenario: null,
        wasteCategory: null,
        wasteType: null,
        wasteQuantity: null,
        wasteCollectionEfficiency: null,
      });
      resetStep2({
        averageDistanceTravelled: null,
        truckCapacity: null,
        fuelEfficiency: null,
      });
      resetStep3([
        {
          businessModelName: null,
          scaleOfPlant: null,
          plantCapacityFactor: 80,
          land: null,
          energy: null,
          water: null,
        },
      ]);
    }
  }, [id]);

  // useEffect(() => {
  //   if (businessModelList?.length > 0) {
  //     const hasNullId = businessModelList.some(
  //       (item) => item.businessModelId === null
  //     );
  //     setHandleDisableButtonForFirstStep(hasNullId);
  //     setErpValue(null);
  //   }
  // }, [businessModelList]);
  useEffect(() => {
    if (
      selectedWasteCategory !== null &&
      selectedValueAddedProduct !== null &&
      businessModelByWasteType.length > 0
    ) {
      getBusinessModelByValueAddedProducts(
        selectedWasteCategory,
        selectedValueAddedProduct,
        businessModelByWasteType.map((md) => md.type)
      )
        .then((res) => {
          setBusinessModel(res?.data?.matchedBusinessModels || []);
          setValue1(
            "businessModels",
            res?.data?.matchedBusinessModels?.map((md) => md.type)
          );
        })
        .catch((error) => {
          setBusinessModel([]);
          setValue1("businessModels", []);
        });
    }
  }, [
    selectedWasteCategory,
    selectedValueAddedProduct,
    businessModelByWasteType.length > 0,
  ]);
  useEffect(() => {
    getPointDetails();
    getAllCategoryList();
    setActiveStep(0);
  }, [id]);

  useEffect(() => {
    setValue1("wtfName", pointDetails?.pointName);
    setValue1("district", pointDetails?.districtName);
    setValue1("wasteQuantity", wasteQuantity);
  }, [pointDetails, wasteQuantity]);

  const getPointDetails = () => {
    setIsLoading(true);
    getPointById(id)
      .then((res) => {
        setPointDetails(res.data);
        return res.data.districtName;
      })
      .then((district) => {
        fetchDistricts(district);
      })
      .catch((error) => {
        setPointDetails({
          districtName: null,
          mapPointArray: null,
          mapPointString: null,
          pointName: null,
        });
      });
  };

  const fetchDistricts = async (districtName) => {
    await getAllFeatureOfLayerByLayerName("ghana_wasteprj")
      .then((res) => {
        const format = new GeoJSON();
        const incomingCrs = res.data.crs?.properties?.name || "EPSG:4326"; // Defaulting to EPSG:4326 if no CRS is found

        // Read the features and reproject if needed
        const features = format.readFeatures(res.data, {
          dataProjection: incomingCrs, // Incoming CRS from the GeoJSON
          featureProjection: "EPSG:3857", // The projection you want to display the features in (for your map)
        });
        const filteredDistricts = features.filter(
          (feature) => feature.get("district") === districtName
        );
        let properties = filteredDistricts[0].getProperties();
        setDistrictDetails(properties);
        setIsLoading(false);
      })
      .catch((error) => {
        setDistrictDetails({
          cassava: null,
          cattlewast: null,
          chicken: null,
          coffeehusk: null,
          corncob: null,
          district: null,
          dists: null,
          fid_: null,
          fid_1: null,
          geometry: null,
          objectid: null,
          oilpalm: null,
          pigwaste: null,
          potatoskin: null,
          region: null,
          rgns: null,
          ricehusk: null,
          sawdust: null,
          shape__are: null,
          shape__len: null,
          shape_area: null,
          shape_leng: null,
          wheatstraw: null,
        });
        setIsLoading(false);
      });
  };

  const getAllCategoryList = () => {
    getAllCtegory()
      .then((res) => {
        let subCate = res.data
          .map((ele) => ele.wasteSubCategories)
          .flat(Infinity);
        setAllWasteTypeList(subCate);
        setCategory(res.data);
      })
      .catch(() => {
        setCategory([]);
      });
  };

  const getAllBusinessModelList = () => {
    getAllBusinessModel()
      .then((res) => {
        setbusinessModelList(res.data);
      })
      .catch((error) => {
        setbusinessModelList([]);
      });
  };
  const getAllValuAddedProductList = () => {
    getAllValueAddedProducts()
      .then((res) => {
        setValueAddedProduct(res.data);
      })
      .catch((error) => {
        setBusinessModel([]);
      });
  };

  let getWasteQuantityByWasteTypeName = (wasteType) => {
    let wasteAmount = Number(districtDetails[wasteType]) || 0;
    setWasteQuantity(wasteAmount);
    //setWasteQuantity(159.11);
  };

  const onSubmitStep1 = (data) => {
    console.log(data);
    // data.moisture = Number(additionalDatas.moisture || 0);
    // data.oil = Number(additionalDatas.oilContent || 0);
    data.wasteQuantity = Number(data.wasteQuantity);
    data.mapPointId = pointDetails.id;
    //data.valueAddedProduct=data.valueAddedProduct
    data["plantSize"]=Number(data.scaleOfPlantValue);
    data["size"] = data.scaleOfPlantValue;
    addWasteQualification(data)
      .then((res) => {
        //console.log(res.data);
        setBusinessSenarioDetails(res.data);
        //setErpValue(res.data);
        setValue2("erp", res?.data?.erpValue);
        setAlertOpen({
          open: true,
          type: "success",
          message: "Information added successfully",
        });
        let step2obj = {
          plantCapacityFactor: data.plantCapacityFactor,
          avgDistanceTraveled: data.avgDistanceTraveled,
          truckCapacity: data.truckCapacity,
          scaleOfPlantValue: data.scaleOfPlantValue,
          expectedLifeOfPlant: data.expectedLifeOfPlant,
        };
        getAllSenariErpDetails(res?.data?.businessScenarioId, step2obj);
        nextStep();
        //return res?.data?.businessScenarioId;
        // setWasteQuantity(null);
      })
      // .then((sneId) => {

      // })
      .catch((error) => {
        setAlertOpen({ open: true, type: "error", message: error.message });
      });
  };

  const getAllSenariErpDetails = (businessScenarioId, step2Data) => {
    let mapPointId = pointDetails?.id;
    let payLoad = { mapPointId, businessScenarioId };
    getSenarioDetails(payLoad).then((resp) => {
      //console.log(resp.data);
      setErpValue(resp.data);
      let sneId=resp.data[0].businessScenarioId;
      return sneId;
    }).then((id)=>{
      onSubmitStep2(step2Data, id);
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setMcdaId(null);
  };

  const nextStep = (data) => {
    handleNext();
  };

  const onSubmitStep2 = (data, businessScenarioId) => {
    const converted = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        typeof value === "string" ? Number(value) : value,
      ])
    );
    let payLoad = {
      ...converted,
      businessScenarioId: businessScenarioId,
    };
    //console.log(payLoad);
    allCalculation(payLoad)
      .then((res) => {
        setAlertOpen({
          open: true,
          type: "success",
          message: "Calculation success",
        });
        setCalculationDetails(res.data);
        // nextStep();
        setDetailsForMCDAApiCall({
          businessScenarioId: businessScenarioId,
          calculationTableId: res?.data?.id,
        });
      })
      .catch((error) => {
        setAlertOpen({
          open: true,
          type: "error",
          message: error.message,
        });
      });
  };

  const downloadReport = () => {
    let obj = {
      mapPointId: pointDetails?.id,
      businessScenarioId: erpValue[0].businessScenarioId,
      // calculationTableId: calculationDetails.id,
      // mcdaId: mcdaId,
    };
    downloadReportData(obj)
      .then((res) => {
        //console.log(res.data)
        // Creating a Blob and triggering download
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = "Report.pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setAlertOpen({
          open: true,
          type: "success",
          message: "Report downloaded successfully!!",
        });
      })
      .catch((error) => {
        setAlertOpen({
          open: true,
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <div className="h-100">
      <Stepper
        activeStep={activeStep}
        connector={null}
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
          my: 2,
        }}
      >
        <Step>
          <StepLabel
            StepIconComponent={DumpsterFireIcon}
            style={{ fontSize: ".1rem" }}
          >
            Waste Qualification
          </StepLabel>
        </Step>
        {/* <Step>
          <StepLabel
            StepIconComponent={TruckDetail}
            style={{ fontSize: ".1rem" }}
          >
            Step 2 input
          </StepLabel>
        </Step> */}
        <Step>
          <StepLabel
            StepIconComponent={ResultView}
            style={{ fontSize: ".1rem" }}
          >
            Result View
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={Mcda} style={{ fontSize: ".1rem" }}>
            MCDA
          </StepLabel>
        </Step>
      </Stepper>

      {activeStep === 0 && (
        <form onSubmit={handleSubmitStep1(onSubmitStep1)} className="m-2">
          <div className="container-fluid card p-3">
            <div className="row">
              <div className="col-md-4">
                <TextField
                  label="WTF Name"
                  {...registerStep1("wtfName", {
                    required: "WTF Name is required",
                  })}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errorsStep1.wtfName}
                  helperText={errorsStep1.wtfName?.message}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="District Name"
                  {...registerStep1("district", {
                    required: "District Name is required",
                  })}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errorsStep1.district}
                  helperText={errorsStep1.district?.message}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="Business Scenario Name"
                  {...registerStep1("businessScenarioName", {
                    required: "Business Scenario Name is required",
                  })}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  error={!!errorsStep1.businessScenarioName}
                  helperText={errorsStep1.businessScenarioName?.message}
                />
              </div>
            </div>

            <h5 className="text-primary">Waste Quantification</h5>
            <div className="row">
              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="waste-category-label">
                    Waste Category
                  </InputLabel>
                  <Controller
                    name="wasteCategory"
                    control={controlStep1}
                    rules={{ required: "Waste Category is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="waste-category-label"
                        label="Waste Category"
                        onChange={(e) => {
                          setSelectedWasteCategory(e.target.value);
                          const selectedWasteTypes = category.find(
                            (ct) => ct.name === e.target.value
                          )?.wasteTypes;
                          setWasteTypeList(selectedWasteTypes);
                          field.onChange(e);
                        }}
                        error={!!errorsStep1.wasteCategory}
                      >
                        {category.map((cate) => (
                          <MenuItem key={cate.name} value={cate.name}>
                            {cate.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.wasteCategory && (
                    <small className="text-danger">
                      {errorsStep1.wasteCategory.message}
                    </small>
                  )}
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="waste-type-label">Waste Type</InputLabel>
                  <Controller
                    name="wasteType"
                    control={controlStep1}
                    rules={{ required: "Waste Type is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="waste-type-label"
                        label="Waste Type"
                        onChange={(e) => {
                          const wasteTypeName = e.target.value
                            .replace(/\s/g, "")
                            .toLowerCase();
                          getWasteQuantityByWasteTypeName(wasteTypeName);
                          const businessModelList = wasteTypeList.find(
                            (p) => p.name == e.target.value
                          )?.businessModels;
                          setBusinessModelByWasteType(businessModelList);
                          //console.log(businessModelList);
                          //setBusinessModel(businessModelList || []);
                          //resetStep1({"businessModelId":[]})
                          // setValue1(
                          //   "businessModels",
                          //   businessModelList.map((md) => md.type)
                          // );
                          field.onChange(e);
                        }}
                        error={!!errorsStep1.wasteType}
                      >
                        {wasteTypeList.map((type, ind) => (
                          <MenuItem key={ind} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.wasteType && (
                    <small className="text-danger">
                      {errorsStep1.wasteType.message}
                    </small>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="value-added-product-label">
                    Value Added Product
                  </InputLabel>
                  <Controller
                    name="valueAddedProduct"
                    control={controlStep1}
                    rules={{ required: "Value Added Product is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="value-added-product-label"
                        label="Value Added Product"
                        onChange={(e) => {
                          // const businessModelList = valuAddedProduct.find(
                          //   (p) => p.id == e.target.value
                          // )?.rrBusinessModels;
                          // setBusinessModel(businessModelList || []);
                          setSelectedValueAddedProduct(e.target.value);
                          field.onChange(e);
                        }}
                        error={!!errorsStep1.valueAddedProduct}
                      >
                        {valuAddedProduct.map((product) => (
                          <MenuItem key={product.id} value={product.name}>
                            {product.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.valueAddedProduct && (
                    <small className="text-danger">
                      {errorsStep1.valueAddedProduct.message}
                    </small>
                  )}
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="business-model-label" shrink>
                    Business Model
                  </InputLabel>
                  <Controller
                    name="businessModels"
                    control={controlStep1}
                    rules={{ required: "Business Model is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="business-model-label"
                        label="Business Model"
                        multiple // Enable multiple selection
                        value={field.value || []} // Ensure value is always an array
                        onChange={(event) => field.onChange(event.target.value)} // Update the value on change
                        error={!!errorsStep1.businessModelId}
                        renderValue={(selected) =>
                          businessModel
                            ?.filter((model) => selected.includes(model.type))
                            .map((model) => model.type)
                            .join(", ")
                        } // Render selected values as a comma-separated string
                      >
                        {businessModel?.map((model, bi) => (
                          <MenuItem
                            key={bi}
                            value={model.type}
                            disabled
                            sx={{
                              color: "gray", // Customize the text color
                              "&.Mui-disabled": {
                                color: "#000000", // Customize the color when disabled
                                backgroundColor: "transparent",
                                opacity: 1,
                              },
                            }}
                          >
                            {/* <Material.Checkbox
                              checked={field.value?.includes(model.type) || false}
                            /> */}
                            <Material.ListItemText primary={model.type} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.businessModelId && (
                    <small className="text-danger">
                      {errorsStep1.businessModelId.message}
                    </small>
                  )}
                </FormControl>
              </div>

              {/* RRR Objective Selection */}
              <div className="col-md-6 mt-3">
                <FormControl size="small" fullWidth>
                  <InputLabel id="rrr-objective-label" >
                    RRR Objective
                  </InputLabel>
                  <Controller
                    name="rrrObjectiveId"
                    control={controlStep1}
                    rules={{ required: "RRR Objective is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="rrr-objective-label"
                        label="RRR Objective"
                        onChange={(e) => {
                          let rrrId = e.target.value;
                          let selectedRRR = rrrObjectiveList?.filter(
                            (ele) => ele.id == rrrId
                          )[0];
                          setSelectedRrrObjectiveList(selectedRRR);
                          field.onChange(e);
                        }}
                      >
                        {rrrObjectiveList.map((objective) => (
                          <MenuItem key={objective.id} value={objective.id}>
                            {objective.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.rrrObjectiveId && (
                    <small className="text-danger">
                      {errorsStep1.rrrObjectiveId.message}
                    </small>
                  )}
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <TextField
                  label="Waste Quantity Generated (Tons / year)"
                  type="number"
                  {...registerStep1("wasteQuantity", {
                    required: "Waste Quantity is required",
                  })}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // error={!!errorsStep1.wasteQuantity}
                  // helperText={errorsStep1.wasteQuantity?.message}
                />
              </div>
              <div className="col-md-6 mt-3">
                <small>Waste Collection Efficiency (%)</small>
                <Controller
                  name="wasteCollectionEfficiency"
                  control={controlStep1}
                  rules={{ required: "Efficiency is required" }}
                  render={({ field }) => (
                    <Slider
                      {...field}
                      min={0}
                      max={100}
                      valueLabelDisplay="on"
                    />
                  )}
                />
                {errorsStep1.wasteCollectionEfficiency && (
                  <small className="text-danger">
                    {errorsStep1.wasteCollectionEfficiency.message}
                  </small>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  label="Scale of Plant (Tons/day)"
                  size="small"
                  type="number"
                  fullWidth
                  {...registerStep1("scaleOfPlantValue", {
                    required: "Scale is required",
                  })}
                  error={!!errorsStep1.scaleOfPlantValue}
                  helperText={errorsStep1.scaleOfPlantValue?.message}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="Avg Distance Travelled (kms)"
                  size="small"
                  type="number"
                  fullWidth
                  {...registerStep1("avgDistanceTraveled", {
                    required: "Avg. Distance Traveled is required",
                  })}
                  error={!!errorsStep1.avgDistanceTraveled}
                  helperText={errorsStep1.avgDistanceTraveled?.message}
                />
              </div>
              <div className="col-md-6 mt-3">
                <Controller
                  name="truckCapacity"
                  control={controlStep1}
                  rules={{ required: "Truck Capacity is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl size="small" fullWidth error={!!error}>
                      <InputLabel id="truck-capacity-label">
                        Select Truck Capacity (MT)
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="truck-capacity-label"
                        label="Select Truck Capacity (MT)"
                      >
                        {truckCapacity?.map((cp) => (
                          <MenuItem key={cp.id} value={cp.capacity}>
                            {cp.transportationType}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </div>

              <div className="col-md-6 mt-2 px-3">
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      Information about Plant CapacityFactor
                    </React.Fragment>
                  }
                >
                  <span style={{ cursor: "pointer" }}>
                    Plant CapacityFactor (%){" "}
                    <i
                      class="fa-solid fa-circle-info  fa-lg  ms-3"
                      style={{ color: "#045372" }}
                    ></i>
                  </span>
                </HtmlTooltip>

                <Controller
                  name="plantCapacityFactor"
                  control={controlStep1}
                  rules={{ required: "Plant Capacity Factor is required" }}
                  defaultValue={80} // Set the default value here in Controller
                  render={({ field, fieldState }) => (
                    <Slider
                      {...field}
                      value={field.value || 80} // Ensure the value is controlled by React Hook Form
                      onChange={(event, newValue) => field.onChange(newValue)} // Update the value in React Hook Form
                      min={0}
                      max={100}
                      valueLabelDisplay="auto"
                    />
                  )}
                />
              </div>

              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="expected-life-label">
                    Expected Operational Life of Plant (Years)
                  </InputLabel>
                  <Controller
                    name="expectedLifeOfPlant"
                    control={controlStep1}
                    rules={{
                      required: "Expected operational life is required",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="expected-life-label"
                        label="Expected Operational Life of Plant (Years)"
                        onChange={(e) => {
                          field.onChange(e); // Pass the value to React Hook Form
                        }}
                        error={!!errorsStep1.expectedOperationalLife}
                      >
                        {expectedLifeOfPlants?.map(({ id, year }) => (
                          <MenuItem key={id} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.expectedOperationalLife && (
                    <small className="text-danger">
                      {errorsStep1.expectedOperationalLife.message}
                    </small>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="row mt-3 align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="ms-2"
                  startIcon={<i className="fa-solid fa-calculator fa-xs"></i>}
                >
                  Calculate Recovery Potential and Next
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}

      {/* {activeStep === 1 && (
        <div className="py-2">
          <div className="card py-2  mt-2">
            <form onSubmit={handleSubmitStep2(onSubmitStep2)}>
              <div className="row px-2">
                <div className="col-md-6">
                  <TextField
                    label="Scale of Plant (Tons/day)"
                    size="small"
                    type="number"
                    fullWidth
                    {...registerStep2("scaleOfPlantValue", {
                      required: "Scale is required",
                    })}
                    error={!!errorsStep2.scaleOfPlantValue}
                    helperText={errorsStep2.scaleOfPlantValue?.message}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Avg Distance Travelled (kms)"
                    size="small"
                    type="number"
                    fullWidth
                    {...registerStep2("avgDistanceTraveled", {
                      required: "Avg. Distance Traveled is required",
                    })}
                    error={!!errorsStep2.avgDistanceTraveled}
                    helperText={errorsStep2.avgDistanceTraveled?.message}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <Controller
                    name="truckCapacity"
                    control={controlStep2}
                    rules={{ required: "Truck Capacity is required" }}
                    render={({ field }) => (
                      <FormControl size="small" fullWidth>
                        <InputLabel id="truck-capacity-label">
                          Select Truck Capacity (MT)
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="truck-capacity-label"
                          label="Select Truck Capacity (MT)"
                          onChange={(e) => {
                            // Update the form state
                            field.onChange(e);

                            // Set fuel efficiency based on the selected value
                            if (e.target.value === "5") {
                              setValue2("fuelEfficiency", 8);
                            } else if (e.target.value === "10") {
                              setValue2("fuelEfficiency", 5);
                            } else if (e.target.value === "15") {
                              setValue2("fuelEfficiency", 3);
                            }
                            setHandleDisableButtonForSecondStep(true);
                            // Call the handleSubmit function after setting the state
                            // handleSubmitStep2(onSubmitStep2)();
                          }}
                        >
                          {truckCapacity?.map((cp, i) => (
                            <MenuItem key={cp.id} value={cp.capacity}>
                              {cp.transportationType}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="col-md-6 mt-2 px-3">
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Information about Plant CapacityFactor
                      </React.Fragment>
                    }
                  >
                    <span style={{ cursor: "pointer" }}>
                      Plant CapacityFactor (%){" "}
                      <i
                        class="fa-solid fa-circle-info  fa-lg  ms-3"
                        style={{ color: "#045372" }}
                      ></i>
                    </span>
                  </HtmlTooltip>

                  <Controller
                    name="plantCapacityFactor"
                    control={controlStep2}
                    rules={{ required: "Plant Capacity Factor is required" }}
                    defaultValue={80} // Set the default value here in Controller
                    render={({ field, fieldState }) => (
                      <Slider
                        {...field}
                        value={field.value || 80} // Ensure the value is controlled by React Hook Form
                        onChange={(event, newValue) => field.onChange(newValue)} // Update the value in React Hook Form
                        min={0}
                        max={100}
                        valueLabelDisplay="auto"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between px-1 mt-3">
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={handleBack}
                  startIcon={
                    <i className="fa-solid fa-backward-step fa-xs"></i>
                  }
                >
                  Previous
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<i className="fa-solid fa-calculator fa-xs"></i>}
                  color="primary"
                >
                  Calculate & Next
                </Button>
              </div>
            </form>
          </div>
        </div>
      )} */}

      {activeStep === 1 && (
        <div className="py-2">
          <div className="card py-2  mt-2">
            <div style={{ width: "100%" }} className="px-1">
              <ResultTable
                erpValue={erpValue}
                calculationDetails={calculationDetails}
                businessModelList={businessModelList}
                callFrom="RESULT_VIEW"
              />
            </div>
            <div className="d-flex justify-content-between px-1 mt-3">
              <Button
                variant="contained"
                color="error"
                type="button"
                onClick={handleBack}
                startIcon={<i className="fa-solid fa-backward-step fa-xs"></i>}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                color="success"
                type="button"
                onClick={() => nextStep()}
                className="ms-2"
                //disabled={handleDisableButtonForFirstStep}
                endIcon={<i className="fa-solid fa-forward-step fa-xs"></i>}
              >
                Save Scenario & Next
              </Button>
            </div>
          </div>
        </div>
      )}

      {activeStep === 2 && (
        <div className="py-2">
          <div className="card py-2  mt-2">
            <div className="row px-2">
              <div className="col-12">
                <WeightAnalysis
                  selectedRrrObjectiveList={selectedRrrObjectiveList}
                  detailsForMCDAApiCall={detailsForMCDAApiCall}
                  setMcdaId={setMcdaId}
                />
              </div>
              {/* <div className="col-12 mt-2">
                {mcdaCalculationDetails.length > 0 && (
                  <ResultTable
                    erpValue={erpValue}
                    calculationDetails={mcdaCalculationDetails}
                    businessModelList={businessModelList}
                    callFrom="MCDA"
                  />
                )}
              </div> */}
            </div>

            <div className="d-flex justify-content-between px-1 mt-3">
              <Button
                variant="contained"
                color="error"
                type="button"
                onClick={handleBack}
                startIcon={<i className="fa-solid fa-backward-step fa-xs"></i>}
              >
                Previous
              </Button>
              <div>
                <Button
                  variant="contained"
                  color="success"
                  //type="submit"
                  className="ms-2"
                  startIcon={<i className="fa-solid fa-download" />}
                  onClick={downloadReport}
                  hidden={mcdaId == null}
                >
                  Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader isLoading={isLoading} />
      <AlertSnakBar aleartOpen={aleartOpen} setAlertOpen={setAlertOpen} />
    </div>
  );
};

export default SuitabilityCalculation;
