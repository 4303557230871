import React, { useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function AlertSnakBar({ aleartOpen, setAlertOpen }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen({
      open:false,
      type:'',
      message:''
    });
  };
  return (
    <>
      <Snackbar sx={{ width: "400px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={aleartOpen.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={aleartOpen.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {aleartOpen.message}
        </Alert>
      </Snackbar>
    </>
  );
}
