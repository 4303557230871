import { rooApiUrl, workspaceName } from "../environment/Environment";
import axiosInstance from "../external-library/axios-interceptor/Interceptor";

export const getFeatureInfoByUrl = async (url) => {
  return await axiosInstance.get(url);
};

export const getAllFeatureOfLayerByLayerName = async (layerName) => {
  return await axiosInstance.get(
    `${rooApiUrl}/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=${workspaceName}:${layerName}&outputFormat=application/json`
  );
};
