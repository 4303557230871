import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import SuitabilityCalculation from "../components/site-suitablity-calculation/SuitabalityCalculation.jsx";
import Register from "../components/registration/Register.jsx";

const Signin = lazy(() => import("../components/signin/Signin.jsx"));
const Layout = lazy(() => import("../components/layout/Layout.jsx"));
const Home = lazy(() => import("../components/home/Home.jsx"));
const UserManagement = lazy(() => import("../components/user-management/UserManagement.jsx"));
const SpatialQuery = lazy(() =>
  import("../components/spatial-query/SpatialQuery.jsx")
);
const UploadDocument=lazy(() => import("../components/document-upload/UploadDocument.jsx"));

const AppRouter = () => {
  const logInDeatils = useSelector((state) => state.auth.isLoggedIn);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(logInDeatils);
  }, [logInDeatils]);

  const router = createBrowserRouter([
    {
      path: "/login",
      element: (
        <Suspense fallback={<div>Loading Sign In...</div>}>
          <Signin />
        </Suspense>
      ),
      loader: () => {
        if (isLoggedIn) {
          return redirect("/");
        }
        return null;
      },
    },
    {
      path: "/register",
      element: (
        <Suspense fallback={<div>Loading Registration...</div>}>
          <Register />
        </Suspense>
      ),
      // loader: () => {
      //   if (isLoggedIn) {
      //     return redirect("/");
      //   }
      //   return null;
      // },
    },
    {
      path: "/",
      element: (
        <Suspense fallback={<div>Loading Layout...</div>}>
          <Layout />
        </Suspense>
      ),
      loader: () => {
        if (!isLoggedIn) {
          return redirect("/login");
        }
        return null;
      },
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<div>Loading Home...</div>}>
              <Home />
            </Suspense>
          ),
          loader: () => {
            if (!isLoggedIn) {
              return redirect("/login");
            }
            return null;
          },
        },
        {
          path: "/user-management",
          element: (
            <Suspense fallback={<div>User-management Loading ...</div>}>
              <UserManagement />
            </Suspense>
          ),
          loader: () => {
            if (!isLoggedIn) {
              return redirect("/login");
            }
            return null;
          },
        },
        {
          path: "/document-upload",
          element: (
            <Suspense fallback={<div>Document upload loading ...</div>}>
              <UploadDocument />
            </Suspense>
          ),
          loader: () => {
            if (!isLoggedIn) {
              return redirect("/login");
            }
            return null;
          },
        },
        {
          path: "/suitability-analysis/:id",
          element: (
            <Suspense fallback={<div>Loading ...</div>}>
              <SuitabilityCalculation />
            </Suspense>
          ),
          loader: () => {
            if (!isLoggedIn) {
              return redirect("/login");
            }
            return null;
          },
        },
        {
          path: "spatial-query",
          element: (
            <Suspense fallback={<div>Loading Spatial Query...</div>}>
              <SpatialQuery />
            </Suspense>
          ),
          loader: () => {
            if (!isLoggedIn) {
              return redirect("/login");
            }
            return null;
          },
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
