import React, { useEffect, useState, useCallback } from "react";
import "./WeightAnalysis.css";
import { useSelector } from "react-redux";
import { Material } from "../../external-library/material/Material";
import Slider from "@mui/material/Slider";
import { useForm, Controller } from "react-hook-form";
import debounce from "lodash.debounce";
import {
  addWeightForCriteria,
  addWeightForSubCriteria,
  getAllCriteriaWithSubcriteria, // Fetching data API
} from "../../services/CriteriaSubCriteria";
import Loader from "../../external-library/loader/Loader";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import {
  getAllBusinessModel,
  mcdaCalculation,
} from "../../services/analysisService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const technicalKeys = [
  { displayName: "ERP (MJ/day)", dataKey: "erpCalculationValue" },
  { displayName: "NRP (kg/day)", dataKey: "nrpCalculationValue" },
  { displayName: "Part Availability", dataKey: "partAvailability" },
  {
    displayName: "Skilled/Unskilled Labour Ratio",
    dataKey: "skilledUnskilledRatio",
  },
  { displayName: "Land Use(ha)", dataKey: "landUse" },
  { displayName: "Water Use(KLD)", dataKey: "waterUse" },
  {
    displayName: "Energy Use(KW / day)",
    dataKey: "energyUse",
  },
  // {
  //   displayName: "Transport Fuel Energy Use (MJ/day)",
  //   dataKey: "fuelEnergyUsage",
  // },
];

const environmentalKeys = [
  { displayName: "GHG Emission (Kg CO2 equiv/day)", dataKey: "ghgEmissions" },
  { displayName: "Noise Emission", dataKey: "noise" },
  { displayName: "Odor Emission", dataKey: "odour" },
];

const socialKeys = [
  { displayName: "Job Creation (people)", dataKey: "jobsCreated" },
  { displayName: "Occupational H&S", dataKey: "healthSafetyRiskFactor" },
  { displayName: "Social Acceptance", dataKey: "societalAcceptanceFactor" },
  { displayName: "Women & Youth Inclusion", dataKey: "womenYouthInclusion" },
];
const economicKeys = [
  // { displayName: "NPV", dataKey: "NPV" },
  { displayName: "ROI (%)", dataKey: "ROI" },
  { displayName: "BCR Ratio", dataKey: "BCR" },
];

const resultView = [
  // { displayName: "NPV", dataKey: "NPV" },
  { displayName: "Feasibility Index", dataKey: "fInd" },
  { displayName: "Rank", dataKey: "rank" },
];
// TreeNode Component (Remains same as you have already defined)
// const TreeNode = ({
//   node,
//   control,
//   register,
//   watch,
//   setValue,
//   handleParentApiCall,
//   handleChildApiCall,
// }) => {
//   const [open, setOpen] = useState(false);
//   const handleToggle = () => setOpen(!open);

//   // Watch parent values
//   const parent1 = watch("Technical") || 0;
//   const parent2 = watch("Environment & Social") || 0;
//   const parent3 = 100 - (parseFloat(parent1) + parseFloat(parent2));

//   // Auto-adjust parent 3 value (Economic) dynamically
//   useEffect(() => {
//     if (parent3 >= 0) setValue("Economic", parent3.toFixed(0));
//   }, [parent1, parent2, parent3, setValue]);

//   return (
//     <div className="tree-node m-1">
//       <div
//         className={
//           node.type === "slider"
//             ? "node-header d-flex flex-column"
//             : "node-header d-flex align-items-center"
//         }
//       >
//         <p
//           style={{
//             fontSize: "1rem",
//             cursor: "pointer",
//             marginBottom: "0px",
//             color: "black",
//           }}
//           onClick={handleToggle}
//         >
//           <i
//             style={{ fontSize: "0.800rem" }}
//             className={`fa-solid ${
//               open ? "fa-chevron-down" : "fa-chevron-right"
//             } me-2 fw-bold`}
//           ></i>
//           {node.name}
//         </p>

//         {/* Parent Node (Input) */}
//         {node.type === "input" && (
//           <div hidden={!open} className="ms-2">
//             <div className="form-group">
//               <input
//                 type="number"
//                 className="form-control"
//                 placeholder={`Enter ${node.name} %`}
//                 required
//                 defaultValue={node.weight || 0}
//                 {...register(node.name, {
//                   // valueAsNumber: true,
//                   validate: (value) => value >= 0 && value <= 100,
//                   onChange: (e) => handleParentApiCall(node.id, e.target.value),
//                 })}
//               />
//             </div>
//           </div>
//         )}

//         {/* Child Node (Slider) */}
//         {node.type === "slider" && (
//           <div hidden={!open}>
//             <div className="form-group">
//               <Controller
//                 name={node.name}
//                 control={control}
//                 defaultValue={node.weight || 1}
//                 render={({ field }) => (
//                   <Slider
//                     {...field}
//                     aria-label={node.name}
//                     max={5}
//                     min={0}
//                     step={0.1}
//                     valueLabelDisplay="on"
//                     sx={{ width: 200 }}
//                     onChange={(event, value) => {
//                       field.onChange(value);
//                       handleChildApiCall(node.id, value);
//                     }}
//                   />
//                 )}
//               />
//             </div>
//           </div>
//         )}
//       </div>

//       {/* Recursively Render Child Nodes */}
//       {open && node.subCriteria?.length > 0 && (
//         <div className="child-nodes ms-3">
//           {node.subCriteria.map((childNode, index) => (
//             <TreeNode
//               key={index}
//               node={childNode}
//               control={control}
//               register={register}
//               watch={watch}
//               setValue={setValue}
//               handleParentApiCall={handleParentApiCall}
//               handleChildApiCall={handleChildApiCall}
//             />
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };
const keysToInclude = [
  "erpCalculationValue",
  "partAvailability",
  "skilledUnskilledRatio",
  "landUse",
  "waterUse",
  "energyUse",
  "jobsCreated",
  "healthSafetyRiskFactor",
  "societalAcceptanceFactor",
  "womenYouthInclusion",
  "ghgEmissions",
  "noise",
  "odour",
  // "NPV",
  "ROI",
  "BCR",
  "fInd",
  "rank",
];

const WeightAnalysis = ({
  selectedRrrObjectiveList,
  detailsForMCDAApiCall,
  setMcdaId,
}) => {
  console.log(detailsForMCDAApiCall, selectedRrrObjectiveList)
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [mcdaPayload, setMcdaPayload] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [allBusinessModel, setAllBusinessModel] = useState([]);
  useEffect(() => {
    getAllBusinessModel().then((res) => {
      setAllBusinessModel(res.data);
    });
    selectedRrrObjectiveList?.criteria?.forEach((ele) => {
      if (ele.name == "Environment") {
        setValue("environmentalDomain", ele.weight);
      } else if (ele.name == "Technical") {
        setValue("technicalDomain", ele.weight);
      } else if (ele.name == "Economic") {
        setValue("economicDomain", ele.weight);
      } else if (ele.name == "Social") {
        setValue("socialDomain", ele.weight);
      }
    });
    let obj = {
      businessScenarioId: detailsForMCDAApiCall?.businessScenarioId,
      rrrObjectiveId: selectedRrrObjectiveList?.id,
      calculationId: detailsForMCDAApiCall?.calculationTableId,
    };
    setMcdaPayload(obj);
  }, [selectedRrrObjectiveList, detailsForMCDAApiCall]);
  // const [treeData, setTreeData] = useState([]); // Store tree data from API
  // const [total, setTotal] = useState(0);
  // const [isLoading,setIsLoading]=useState(false)
  // const siteSuitabilityInfo = useSelector(
  //   (state) => state.SiteSuitablitySlice.siteSuitablityDetails
  // );

  // // API call for Parent Node
  // const handleParentApiCall = useCallback(
  //   debounce(async (parentId, value) => {
  //     addWeightForCriteria(parentId, value);
  //   }, 500),
  //   []
  // );

  // // API call for Child Node
  // const handleChildApiCall = useCallback(
  //   debounce(async (childId, value) => {
  //     addWeightForSubCriteria(childId, value);
  //   }, 500),
  //   []
  // );

  // // Fetch data from API and populate treeData
  // useEffect(() => {
  //   setIsLoading(true)
  //   getAllCriteriaWithSubcriteria().then((res) => {
  //     let manipulateTreeData = processApiData(res.data);
  //     setTreeData(manipulateTreeData);
  //     setIsLoading(false) // Populate treeData with API response
  //   }).catch((error)=>{
  //     setIsLoading(false)
  //   });
  // }, []);

  // const processApiData = (data) => {
  //   return data.map((parentNode) => ({
  //     ...parentNode,
  //     type: "input", // Add type: "input" for parent
  //     subCriteria:
  //       parentNode.subCriteria?.map((childNode) => ({
  //         ...childNode,
  //         type: "slider", // Add type: "slider" for child
  //       })) || [], // Ensure subCriteria defaults to an empty array
  //   }));
  // };

  // const calculateTotalValue = (data) => {
  //   let totalValue = 0;
  //   treeData.forEach((parentNode) => {
  //     const parentValue = data[parentNode.name];
  //     const childCount = parentNode.subCriteria.length;

  //     if (parentValue && childCount > 0) {
  //       const childValue = parentValue / childCount;
  //       parentNode.subCriteria.forEach((childNode) => {
  //         const childSliderValue = data[childNode.name] || 0;
  //         totalValue += childValue * childSliderValue;
  //       });
  //     }
  //   });
  //   return totalValue.toFixed(2);
  // };
  const getNamesByIds = (idList) => {
    return idList
      .map((id) => {
        const match = allBusinessModel.find((model) => model.id === id);
        return match ? match.type : null; // Return type if found, otherwise null
      })
      .filter(Boolean); // Remove null values
  };
  const onSubmit = (data) => {
    if (mcdaPayload !== null) {
      mcdaCalculation(mcdaPayload)
        .then((res) => {
          console.log(res.data);
          setMcdaId(res?.data?.id);
          let dataForTable = createTableDataStructure(res?.data);
          setTableData(dataForTable);
          let column = Object.keys(dataForTable[0]).filter(
            (ele) => ele !== "key"
          );
          setColumnNames(column);
        })
        .catch((error) => { });
    }
  };
  const createTableDataStructure = (data) => {
    const {
      businessModelIds,
      weightedErpValues,
      weightedPartsAvailability,
      weightedLabourCostFactor,
      weightedLandUse,
      weightedWaterUse,
      weightedEnergyUse,
      weightedJobsCreated,
      weightedHealthSafetyRiskFactor,
      weightedSocietalAcceptanceFactor,
      weightedWomenYouthInclusion,
      weightedGHG,
      weightedNoise,
      weightedOdour,
      weightedROI,
      weightedBCR,
      closenessCoefficients,
      ranks,
    } = data;

    const dataMap = {
      erpCalculationValue: weightedErpValues,
      partAvailability: weightedPartsAvailability,
      skilledUnskilledRatio: weightedLabourCostFactor,
      landUse: weightedLandUse,
      waterUse: weightedWaterUse,
      energyUse: weightedEnergyUse,
      jobsCreated: weightedJobsCreated,
      healthSafetyRiskFactor: weightedHealthSafetyRiskFactor,
      societalAcceptanceFactor: weightedSocietalAcceptanceFactor,
      womenYouthInclusion: weightedWomenYouthInclusion,
      ghgEmissions: weightedGHG,
      noise: weightedNoise,
      odour: weightedOdour,
      ROI: weightedROI,
      BCR: weightedBCR,
      fInd: closenessCoefficients,
      rank: ranks,
    };
    const expandValues = (values, length) => {
      if (values.length === 1) {
        return Array(length).fill(String(values[0]));
      }
      return values;
    };

    const dataStructure = keysToInclude.map((key) => {
      const obj = { key };
      const businessModelNameList = getNamesByIds(businessModelIds);
      const expandedValues = expandValues(
        dataMap[key] || [],
        businessModelNameList.length
      );

      businessModelNameList.forEach((name, index) => {
        obj[name] = expandedValues[index] ?? null;
      });
      return obj;
    });

    return dataStructure;
  };
  // const backToPreviousForm = () => {
  //   // setDoMcda(false);
  // };

  const fixedTableData = (value, dataKey) => {
    return dataKey == "rank"
      ? value
      : dataKey == "BCR"
        ? Number(value).toFixed(6)
        : Number(value).toFixed(4);
  };

  const renderSection = (title, keys) => {
    return (
      <>
        <TableRow>
          <TableCell
            colSpan={columnNames.length + 1}
            align="left"
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              backgroundColor: "#d1dee2",
              color: "#003031",
              border: "2px solid #070750",
            }}
          >
            {title}
          </TableCell>
        </TableRow>
        {keys.map(({ displayName, dataKey }, index) => (
          <TableRow key={index}>
            <TableCell
              style={{
                fontSize: "0.7rem",
                fontWeight: "bold",
              }}
            >
              {displayName}
            </TableCell>
            {columnNames.map((col, colIndex) => (
              <TableCell
                key={colIndex}
                align="center"
                style={{
                  fontSize: "0.7rem",
                  fontWeight: 500,
                }}
              >
                {tableData.find((row) => row.key === dataKey)?.[col] !==
                  undefined
                  ? fixedTableData(
                    tableData.find((row) => row.key === dataKey)?.[col],
                    dataKey
                  )
                  : "--"}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="tree-view mt-6 w-100 bg-light"
        style={{
          // overflowY: "auto",

          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
          borderRadius: 5,
        }}
      >
        <div className="row p-3">
          <div className="col-6 fw-bold">Technical</div>
          <div className="col-6">
            <Controller
              name="technicalDomain"
              control={control}
              rules={{ required: "Technical Domain is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Technical Domain (%)"
                  type="text"
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.technicalDomain && (
              <small className="text-danger">
                {errors.technicalDomain.message}
              </small>
            )}
          </div>

          <div className="col-6 mt-3 fw-bold">Environmental</div>
          <div className="col-6 mt-3">
            <Controller
              name="environmentalDomain"
              control={control}
              rules={{ required: "Environmental Domain is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Environmental Domain (%)"
                  type="text"
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.environmentalDomain && (
              <small className="text-danger">
                {errors.environmentalDomain.message}
              </small>
            )}
          </div>

          <div className="col-6 fw-bold mt-3">Economic</div>
          <div className="col-6 mt-3">
            <Controller
              name="economicDomain"
              control={control}
              rules={{ required: "Economic Domain is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Economic Domain (%)"
                  type="text"
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.economicDomain && (
              <small className="text-danger">
                {errors.economicDomain.message}
              </small>
            )}
          </div>

          <div className="col-6 fw-bold mt-3">Social</div>
          <div className="col-6 mt-3">
            <Controller
              name="socialDomain"
              control={control}
              rules={{ required: "Social Domain is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Social Domain (%)"
                  type="text"
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.socialDomain && (
              <small className="text-danger">
                {errors.socialDomain.message}
              </small>
            )}
          </div>
          <div className="col-12 mt-3">
            <Button
              variant="contained"
              color="success"
              type="submit"
              className=" float-end mt-3"
              startIcon={<i className="fa-regular fa-floppy-disk fa-xs"></i>}
            >
              Submit
            </Button>
          </div>
        </div>

        {/* <div className="p-2 bg-light fw-bold">MCDA Matrix</div>
      {treeData.map((node, index) => (
        <TreeNode
          key={index}
          node={node}
          control={control}
          register={register}
          watch={watch}
          setValue={setValue}
          handleParentApiCall={handleParentApiCall}
          handleChildApiCall={handleChildApiCall}
        />
      ))} */}

        {/* Form buttons */}
        {/* <div className="mt-4 mb-2 me-2 d-flex justify-content-end">
        <span className="fw-bold me-5">Total Value Is :{total}</span>
      
        <Material.Button
          variant="contained"
          color="success"
          type="submit"
          size="small"
          startIcon={
            <i className="fa-solid fa-calculator fa-xs"></i>
          }
        >
          Calculate
        </Material.Button>
      </div> */}
        {/* <Loader isLoading={isLoading} /> */}
      </form>
      {tableData.length > 0 && (
        <TableContainer component={Paper} className="mt-3">
          <Table size="small">
            <TableHead>
              <TableRow
                className="bg-primary"
                style={{ border: "2px solid #1F3F49" }}
              >
                <TableCell
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    backgroundColor: "#1F3F49",
                  }}
                  align="left"
                  className="text-light"
                >
                  Key
                </TableCell>
                {columnNames.map((col, index) => (
                  <TableCell
                    key={index}
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      backgroundColor: "#1F3F49",
                    }}
                    align="center"
                    className="text-light"
                  >
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderSection("Technical", technicalKeys)}
              {renderSection("Environmental", environmentalKeys)}
              {renderSection("Social", socialKeys)}
              {renderSection("Economic", economicKeys)}
              {renderSection("Result View", resultView)}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default WeightAnalysis;
