import React from "react";
import { RotatingLines } from "react-loader-spinner";
import './Loader.css'
export default function Loader({ isLoading }) {

  return (
    <div className="loading" hidden={!isLoading}>
      <RotatingLines
        visible={isLoading}
        //height="96"
        width="96"
        //color={'#ad0303'}
        strokeColor="#083f63"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}
