import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SaveIcon from "@mui/icons-material/Save";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./Register.css";
import AlertSnakBar from "../dialogs/alert-message/AlertSnakBar";
import { doRegistration } from "../../services/LoginService";

const Register = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const passwordValue = watch("password");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [aleartOpen, setAlertOpen] = useState({
    open: false,
    type: "",
    message: "",
  });
  const handlePasswordToggle = () => setShowPassword(!showPassword);
  const handleConfirmPasswordToggle = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = (data) => {
    //delete data.confirmPassword;
    doRegistration(data)
      .then((res) => {
        //console.log(res.data);
        setAlertOpen({
          open: true,
          type: "success",
          message: "Registration success!!",
        });
        navigate("/login");
      })
      .catch((error) => {
        setAlertOpen({
          open: true,
          type: "error",
          message: `${error.message}`,
        });
      });
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-md-6 card p-4">
            <Typography
              variant="h6"
              gutterBottom
              className="d-flex justify-content-center mb-3 fw-bold"
            >
              New User Registration Form
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Fullname Field */}
              <div className="row mb-2">
                <div className="col-12 mb-2" style={{ fontWeight: 500 }}>
                  Fullname
                </div>
                <div className="col-12">
                  <Controller
                    name="fullName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Fullname is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Fullname"
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={!!errors.fullname}
                        helperText={errors.fullname?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BadgeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              {/* Username Field */}
              <div className="row mb-2">
                <div className="col-12 mb-2" style={{ fontWeight: 500 }}>
                  Username
                </div>
                <div className="col-12">
                  <Controller
                    name="username"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Username is required",
                      minLength: {
                        value: 6,
                        message: "Username must be at least 6 characters long",
                      },
                      pattern: {
                        value:
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                        message:
                          "Username must contain at least one letter, one number, and one special character",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Username"
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={!!errors.username}
                        helperText={errors.username?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              {/* Password Field */}
              <div className="row mb-2">
                <div className="col-12 mb-2" style={{ fontWeight: 500 }}>
                  Password
                </div>
                <div className="col-12">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters long",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <KeyIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handlePasswordToggle}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              {/* Confirm Password Field */}
              <div className="row mb-3">
                <div className="col-12 mb-2" style={{ fontWeight: 500 }}>
                  Confirm Password
                </div>
                <div className="col-12">
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Confirm Password is required",
                      validate: (value) =>
                        value === passwordValue || "Passwords do not match",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type={showConfirmPassword ? "text" : "password"}
                        label="Confirm Password"
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKeyIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleConfirmPasswordToggle}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div className="row">
                <div className="col-12 d-flex justify-content-between">
                  <Button
                    component={Link}
                    to="/login"
                    variant="contained"
                    color="error"
                    size="small"
                    startIcon={<KeyboardBackspaceIcon />}
                    sx={{ textDecoration: "none" }}
                  >
                    Back to login
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    //fullWidth
                    size="small"
                    startIcon={<SaveIcon />}
                  >
                    Register User
                  </Button>
                </div>
              </div>
            </form>
            <AlertSnakBar aleartOpen={aleartOpen} setAlertOpen={setAlertOpen} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
